import 'intersection-observer'
import './theme/styles'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import routes from './routes'
import useSessionData from 'app/utils/useSessionData'
import truckLineImg from 'assets/img/food-truck-line-share.png'
import env from 'env'
import { FeatureFlagProvider } from 'app/utils/feature-flags'
import CookieConsent from 'app/theme/layout/CookieConsent'
import config from './config'

export default function App({ url }) {
  useSessionData()

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={url} />
        <meta name="og:url" content={url} />
        <meta name="og:image" content={truckLineImg} />
      </Helmet>
      <FeatureFlagProvider flags={config.featureFlags}>
        <Switch>
          {routes.map((route, key) => (
            <Route
              key={key}
              exact={route.exact !== false}
              path={route.path}
              children={props => (
                <route.component {...props} url={url} />
              )}
            />
          ))}
        </Switch>
      </FeatureFlagProvider>
      <CookieConsent />
    </div>
  )
}
