import { ApiClient } from 'jsonapi-react'
import { GraphitiPlugin } from 'jsonapi-react-plugin-graphiti'
import env from '../env'
import { format, parseISO } from 'date-fns'

const getPhotoUrl = path => `${env.MEDIA_URL}/${path}`

export default function createClient(options) {
  return new ApiClient({
    ...options,
    plugins: [
      new GraphitiPlugin(),
    ],
    headers: {
      'X-Application-Id': 'rh',
      'Use-Replica': 1,
    },
    schema: {
      leads: {
        fields: {
          cc_auth: {
            readOnly: true
          },
          start_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: date => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
          end_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
            serialize: (date, attrs) => (
              date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
            )
          },
        },
        relationships: {
          contact: 'contacts',
          occasion: 'occasions',
          proposal: {
            type: 'proposals',
          },
        }
      },
      packages: {
        relationships: {
          caterer: 'caterers',
          categories: 'package_categories',
          items: 'package_items',
          photo: 'photo'
        }
      },
      photos: {
        fields: {
          url: {
            resolve: (val, attrs) => getPhotoUrl(attrs.path)
          },
        },
        relationships: {
          subject: {
            polymorphic: true,
          }
        }
      },
      calendar_events: {
        fields: {
          start_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
          },
          end_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
          },
        }
      },
      events: {
        fields: {
          start_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
          },
          end_date: {
            resolve: date => (
              date ? parseISO(date) : undefined
            ),
          },
        }
      },
      proposals: {
        relationships: {
          bookings: {
            type: 'bookings',
          },
          agreement: {
            type: 'agreements',
          },
        }
      },
      payments: {
        fields: {
          date_paid: {
            type: 'date',
          },
          created_at: {
            type: 'date',
            readOnly: true,
          },
          updated_at: {
            type: 'date',
            readOnly: true,
          }
        },
        relationships: {
          items: {
            type: 'payment_items',
          },
          proposal: {
            type: 'proposals',
          },
        },
      },
      payment_items: {
        relationships: {
          payment: {
            type: 'payments',
          },
          payable: {
            polymorphic: true
          },
        }
      },
      orders: {
        fields: {
          token: {
            readOnly: true,
          },
          status: {
            readOnly: true,
          },
          subtotal_price: {
            readOnly: true,
          },
          total_tax: {
            readOnly: true,
          },
          total_price: {
            readOnly: true,
          },
          processed_at: {
            readOnly: true,
          },
          created_at: {
            readOnly: true,
          },
          updated_at: {
            readOnly: true,
          },
        },
        relationships: {
          items: {
            type: 'order_items'
          }
        }
      },
      order_items: {
        order_id: {
          readOnly: true,
        },
        product_type: {
          readOnly: true,
        },
        product_id: {
          readOnly: true,
        },
        status: {
          readOnly: true,
        },
        price: {
          readOnly: true,
        },
        total_tax: {
          readOnly: true,
        },
        total_price: {
          readOnly: true,
        },
        created_at: {
          readOnly: true,
        },
        updated_at: {
          readOnly: true,
        },
        relationships: {
          order: {
            type: 'orders'
          },
          product: {
            polymorphic: true,
          }
        }
      },
      reviews: {
        created_at: {
          readOnly: true,
        },
        updated_at: {
          readOnly: true,
        },
        relationships: {
          booking: {
            type: 'bookings'
          },
          event: {
            type: 'events'
          },
          caterer: {
            type: 'caterers'
          },
        }
      },
      review_tags: {
        created_at: {
          readOnly: true,
        },
        updated_at: {
          readOnly: true,
        },
      }
    }
  })
}
