let env

if (typeof window !== 'undefined') {
  env = {
    ...window.__APP_STATE__?.env,
  }
} else {
  env = {
    APP_ENV: process.env.APP_ENV,
    APP_VERSION: process.env.APP_VERSION,
    API_URL: process.env.API_URL,
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    MEDIA_URL: process.env.MEDIA_URL,
    IMAGE_URL: process.env.IMAGE_URL,
    BLOG_URL: process.env.BLOG_URL,
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
    GA_CONVERSION_ID: process.env.GA_CONVERSION_ID,
    GA_GTM_ID: process.env.GA_GTM_ID,
    SENTRY_DSN: process.env.SENTRY_DSN,
    STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
    KITCHEN_URL: process.env.KITCHEN_URL,
    LINKEDIN_EVENT_SPECIFIC_CONVERSION_ID: process.env.LINKEDIN_EVENT_SPECIFIC_CONVERSION_ID,
    POSTHOG_TOKEN: process.env.POSTHOG_TOKEN,
    POSTHOG_HOST: process.env.POSTHOG_HOST,
    HUBSPOT_CALENDAR_URL: process.env.HUBSPOT_CALENDAR_URL || "https://meetings.hubspot.com/andres-menendez/rh-catering-meeting-website",
  }
}

export default env
