import React from 'react'
import PushMenu from './PushMenu'
import logo from 'assets/svg/logo-bg.svg'
import styles from './Header.module.css'
import posthog from 'posthog-js'

export default function Header(props) {
  const primaryButtonText = 'Book a Truck'

  const handlePrimaryButtonOnClick = () => {
    posthog.capture('book-a-truck-button-clicked', {
      url: window.location.href,
    })

    if (props.openModal) {
      props.openModal()
    }
  }

  return (
    <header className="relative z-50 bg-white">
      <PushMenu className="lg:hidden">
        <button children={primaryButtonText} onClick={handlePrimaryButtonOnClick} className="btn btn-blue px-4 py-2 inline-block mb-6" />

        <div className={[styles.pushMenuLinks, 'text-white font-light']}>
          <div className="text-xl mb-6">
            <a href="/food-trucks/">Find Trucks</a>
            <a href="/food-truck-catering/">Catering</a>
            <a href="/experiential-marketing-agency/">Brand Activations</a>
          </div>
          <div>
            <a href="/pages/about/">About</a>
            <a href="/blog/">Blog</a>
            <a href="/pages/terms_and_conditions/">Terms of Service</a>
            <a href="/pages/privacy/policy/">Privacy Policy</a>
            <a href="#">Contact Us</a>
          </div>
        </div>
      </PushMenu>
      <div className="hidden lg:flex container max-w-5xl">
        <a href="/" className='py-2'>
          <img src={logo} title="Roaming Hunger" alt="20,000,000+ happy eaters and thousands of companies served since 2009." style={{width:"80px",height:"80px"}}/>
        </a>
        <ul className={[styles.masthead, 'container max-w-5xl flex justify-end']}>
          <li>
            <a href="/food-truck-catering/">Catering</a>
            < LocalCitySubNav />
            <div className={[styles.subnav, props.occasionMenu && styles.subnavEnabled]}>
              <div className={[styles.occasionList, 'container max-w-5xl grid grid-cols-4 gap-6']}>
                <a href="/food-truck-catering/corporate-catering/">
                  <p className={styles.subnavHeadings}>Workplace Catering</p>
                  <p>Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, Employee Appreciation, Client Appreciation, Holiday Parties, Milestone Celebrations, Retail Events</p>
                </a>
                <a href="/food-truck-catering/food-truck-wedding/">
                  <p className={styles.subnavHeadings}>Wedding Catering</p>
                  <p>Wedding Receptions, Rehearsal Dinners, Late Night Snacks, Day-After Brunches</p>
                </a>
                <a href="/food-truck-catering/campus-catering/">
                  <p className={styles.subnavHeadings}>Food Trucks on Campus</p>
                  <p>Student Organizations, On/Off-Campus Events, Graduation, Prom, School Dances, School Fundraisers, Recruitment Events, Alumni Events</p>
                </a>
                <a href="/food-truck-catering/large-event-food-concessions/">
                  <p className={styles.subnavHeadings}>Large-Scale Events and Multi-City Event Series</p>
                  <p>Sporting Events, Music Festivals, Arena Concerts, National Event Series, Marathons and Fun Runs</p>
                </a>
                <a href="/food-truck-catering/film-production-catering/">
                  <p className={styles.subnavHeadings}>Production Catering</p>
                  <p>Movie Set Catering, TV and Film Catering, 24 Hour Production Catering, On Location Catering, Photo Shoots</p>
                </a>
                <a href="/food-truck-catering/local-community-concessions/">
                  <p className={styles.subnavHeadings}>Community Events</p>
                  <p>Street Fairs, Neighborhood Block Parties, Flea Markets, Little League Games, Car Shows, Real Estate Open Houses, Religious Congregations and Ministries, High School Football Games, Concerts in the Park</p>
                </a>
                <a href="/food-truck-catering/conference-and-event-catering/">
                  <p className={styles.subnavHeadings}>Professional Events</p>
                  <p>Conferences, Conventions, Expos</p>
                </a>
                <a href="/food-truck-catering/party-catering/">
                  <p className={styles.subnavHeadings}>Private Parties</p>
                  <p>Birthday, Holiday, Graduation, Anniversary, Bar/Bat Mitzvahs, Baby Shower, House Warming</p>
                </a>
              </div>
            </div>
          </li>
          <li>
            <a href="/food-trucks/">Find Trucks</a>
          </li>
          <li><a href="/experiential-marketing-agency/">Brand Activations</a></li>
          <li className="flex justify-center">
            <button
              children={primaryButtonText}
              onClick={handlePrimaryButtonOnClick}
              className="btn btn-blue border-0 m-auto px-4 py-2 rounded-full block"
            />
          </li>
        </ul>
      </div>
    </header>
  )
}

const LocalCitySubNav = () => (
  <div className={[styles.subnav, styles.subnavEnabled]}>
    <ul className={[styles.cityList, 'container']}>
      <li><a href="/ann-arbor-mi/food-truck-catering/">Ann Arbor</a></li>
      <li><a href="/atlanta-ga/food-truck-catering/">Atlanta</a></li>
      <li><a href="/austin-tx/food-truck-catering/">Austin</a></li>
      <li><a href="/baltimore-md/food-truck-catering/">Baltimore</a></li>
      <li><a href="/boston-ma/food-truck-catering/">Boston</a></li>
      <li><a href="/charlotte-nc/food-truck-catering/">Charlotte</a></li>
      <li><a href="/chicago-il/food-truck-catering/">Chicago</a></li>
      <li><a href="/cincinnati-oh/food-truck-catering/">Cincinnati</a></li>
      <li><a href="/cleveland-oh/food-truck-catering/">Cleveland</a></li>
      <li><a href="/columbus-oh/food-truck-catering/">Columbus</a></li>
      <li><a href="/dallas-tx/food-truck-catering/">Dallas</a></li>
      <li><a href="/denver-co/food-truck-catering/">Denver</a></li>
      <li><a href="/detroit-mi/food-truck-catering/">Detroit</a></li>
      <li><a href="/hartford-ct/food-truck-catering/">Hartford</a></li>
      <li><a href="/honolulu-hi/food-truck-catering/">Honolulu</a></li>
      <li><a href="/houston-tx/food-truck-catering/">Houston</a></li>
      <li><a href="/indianapolis-in/food-truck-catering/">Indianapolis</a></li>
      <li><a href="/jacksonville-fl/food-truck-catering/">Jacksonville</a></li>
      <li><a href="/jersey-city-nj/food-truck-catering/">Jersey City</a></li>
      <li><a href="/kansas-city-mo/food-truck-catering/">Kansas City</a></li>
      <li><a href="/las-vegas-nv/food-truck-catering/">Las Vegas</a></li>
      <li><a href="/london-united-kingdom/food-truck-catering/">London</a></li>
      <li><a href="/los-angeles-ca/food-truck-catering/">Los Angeles</a></li>
      <li><a href="/miami-fl/food-truck-catering/">Miami</a></li>
      <li><a href="/milwaukee-wi/food-truck-catering/">Milwaukee</a></li>
      <li><a href="/minneapolis-mn/food-truck-catering/">Minneapolis</a></li>
      <li><a href="/nashville-tn/food-truck-catering/">Nashville</a></li>
      <li><a href="/new-orleans-la/food-truck-catering/">New Orleans</a></li>
      <li><a href="/new-york-ny/food-truck-catering/">New York</a></li>
      <li><a href="/oklahoma-city-ok/food-truck-catering/">Oklahoma City</a></li>
      <li><a href="/omaha-ne/food-truck-catering/">Omaha</a></li>
      <li><a href="/orange-county-ca/food-truck-catering/">Orange County</a></li>
      <li><a href="/orlando-fl/food-truck-catering/">Orlando</a></li>
      <li><a href="/philadelphia-pa/food-truck-catering/">Philadelphia</a></li>
      <li><a href="/phoenix-az/food-truck-catering/">Phoenix</a></li>
      <li><a href="/pittsburgh-pa/food-truck-catering/">Pittsburgh</a></li>
      <li><a href="/portland-or/food-truck-catering/">Portland</a></li>
      <li><a href="/providence-ri/food-truck-catering/">Providence</a></li>
      <li><a href="/raleigh-durham-nc/food-truck-catering/">Raleigh-Durham</a></li>
      <li><a href="/sacramento-ca/food-truck-catering/">Sacramento</a></li>
      <li><a href="/salt-lake-city-ut/food-truck-catering/">Salt Lake City</a></li>
      <li><a href="/san-antonio-tx/food-truck-catering/">San Antonio</a></li>
      <li><a href="/san-diego-ca/food-truck-catering/">San Diego</a></li>
      <li><a href="/san-francisco-ca/food-truck-catering/">San Francisco</a></li>
      <li><a href="/san-jose-ca/food-truck-catering/">San Jose</a></li>
      <li><a href="/seattle-wa/food-truck-catering/">Seattle</a></li>
      <li><a href="/st-louis-mo/food-truck-catering/">St. Louis</a></li>
      <li><a href="/tampa-fl/food-truck-catering/">Tampa</a></li>
      <li><a href="/toronto-on-canada/food-truck-catering/">Toronto</a></li>
      <li><a href="/vancouver-bc-canada/food-truck-catering/">Vancouver</a></li>
      <li><a href="/washington-dc/food-truck-catering/">Washington DC</a></li>
      <li><a href="/food-truck-catering/">All Cities</a></li>
    </ul>
  </div>
)

