import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Portal } from 'lib/portal'
import logo from 'assets/svg/logo-bg.svg'
import styles from './PushMenu.module.css'

const listenerOpts = {
  passive: true,
  capture: true,
}

export default function PushMenu(props) {
  // menu state
  const [menuOpen, toggleMenu] = useState(false)

  // menu reference
  const menuRef = useRef()
  const closeRef = useRef()

  // handle clicks outside the menu
  const handleBodyClick = useCallback(event => {
    if (event.target === closeRef.current || event.target.parentNode === closeRef.current) {
      return
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      toggleMenu(false)
    }
  }, [])

  // add base body class
  useEffect(() => {
    document.body.classList.add(styles.body)
    return () => {
      document.body.classList.add(styles.body)
    }
  }, [])

  // handle menu state changes
  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add(styles.open)
      document.body.addEventListener('click', handleBodyClick, listenerOpts)
    }
    return () => {
      if (menuOpen) {
        document.body.classList.remove(styles.open)
        document.body.removeEventListener('click', handleBodyClick, listenerOpts)
      }
    }
  }, [menuOpen])

  return (
    <div className={[props.className, styles.container]}>
      <div className={[styles.navbar, 'flex items-center justify-center overflow-hidden']}>
        <a href="/" className={styles.logo}>
          <img src={logo} title="Roaming Hunger" alt="Roaming Hunger" />
        </a>
        <button
          type="button"
          ref={closeRef}
          children={<span />}
          onClick={() => toggleMenu(!menuOpen)}
          className={[
            styles.toggle,
            'absolute right-0 text-center text-white focus:outline-none'
          ]}
        />
      </div>
      <Portal ref={menuRef} className={[
        styles.menu,
        'overflow-auto bg-navy fixed top-0 bottom-0 right-0 p-6'
      ]}>
        {props.children}
      </Portal>
    </div>
  )
}
