import { useMemo } from 'react'
import { stringify, parse } from 'qs'
import { useLocation } from 'react-router-dom'

export function useSearchParams() {
  const location = useLocation()

  const params = useMemo(() => (
    parse(location.search.substr(1))
  ), [location.search])

  const setParams = next => {
    const search = stringify({ ...params, ...next }, { encode: false, arrayFormat: 'comma' })
    window.location = `${location.pathname}${search ? '?' : ''}${search}`
  }

  return [
    params,
    setParams,
  ]
}
