import React, { useState, useEffect } from 'react'
import env from 'env'
import { format } from 'date-fns'
import styles from './BlogLinks.module.css'
import { useAppContext } from 'app/AppContext'
import StaticContent from 'app/utils/StaticContent'

const fetchBlogLinks = () => (
  fetch(`${env.BLOG_URL}/feed/json`).then(res => res.ok ? res.json() : null)
)

export default function BlogLinkContainer() {
  return (
    <StaticContent>
      <BlogLinks />
    </StaticContent>
  )
}

function BlogLinks() {
  const context = useAppContext()
  const [data, setData] = useState(context.blogLinks)

  if (typeof window === 'undefined' && context.blogLinks === undefined) {
    context.promises = context.promises || []
    context.promises.push(
      fetchBlogLinks().then(data => {
        context.blogLinks = data
      }).catch(() => {
        context.blogLinks = null
      })
    )
  }

  useEffect(() => {
    if (data === undefined) {
      fetchBlogLinks().then(data => {
        setData(data)
      }).catch(() => {})
    }
  }, [])

  if (!data) {
    return null
  }

  return (
    <div className={styles.blogLinks}>
      <h3 className={styles.heading}>
        Roaming Hunger Blog
      </h3>
      <ul>
        {data?.items?.slice(0, 5).map((item, index) => (
          <li key={index}>
            <a
              href={item.url}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <div>
              {format(new Date(item.date_modified), 'MMMM do, yyyy')}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
