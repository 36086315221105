import { useState, useEffect } from 'react'
import { usePostHog } from './usePostHog'

export function useFeatureFlagVariantKey(flag, { autoLoad } = {}) {
  const { posthog, onFeatureFlags } = usePostHog()

  const [featureFlagVariantKey, setFeatureFlagVariantKey] = useState(
    posthog.getFeatureFlag(flag)
  )

  useEffect(() => {
    return onFeatureFlags(() => {
      setFeatureFlagVariantKey(posthog.getFeatureFlag(flag))
    }, autoLoad)
  }, [posthog, flag])

  return featureFlagVariantKey
}

