import React, { useEffect } from 'react'
import useSessionStorage from 'lib/useSessionStorage'
import { parse } from 'qs'

const utm_keys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_adgroup',
]

export default function useSessionData(key = 'user_session', initialData = {}) {
  const [data, setData] = useSessionStorage(key, initialData)

  useEffect(() => {
    const meta = {}

    if (!data.meta || !data.meta.origin_url) {
      meta.origin_url = window.location.pathname
    }

    const query = parse(location.search.substr(1))

    utm_keys.forEach(key => {
      const val = query[key]

      if (val) {
        meta[key] = val
      }
    })

    if (Object.keys(meta).length) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          ...meta,
        }
      })
    }
  }, [])

  return [data, setData]
}
