import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { loadableReady } from '@loadable/component'
import { BrowserRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { CookieProvider } from 'lib/cookies'
import { ApiProvider, ApiClient } from 'jsonapi-react'
import App from 'app/App'
import { AppContext } from 'app/AppContext'
import ErrorPage from 'app/pages/ErrorPage'
import { HelmetProvider } from 'react-helmet-async'
import createClient from './app/createClient'
import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { NotificationProvider } from 'app/theme/Notification'
import env from 'env'
import posthog from 'posthog-js'
import { PostHogProvider } from 'lib/posthog'
import { UID_COOKIE_NAME } from 'app/utils/useUid'

const cookies = new Cookies()

let posthogBootstrap
if (window.__APP_STATE__?.posthog) {
  posthogBootstrap = {
    ...window.__APP_STATE__.posthog,
    distinctID: cookies.get(UID_COOKIE_NAME),
  }
}

posthog.init(
  env.POSTHOG_TOKEN,
  {
    api_host: env.POSTHOG_HOST,
    autocapture: false,
    disable_session_recording: true,
    advanced_disable_feature_flags_on_first_load: true,
    bootstrap: posthogBootstrap,
    persistence: 'cookie'
  }
)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: env.SENTRY_DSN,

    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [new TracingIntegrations.BrowserTracing()],

    // To set a uniform sample rate
    tracesSampleRate: env.SENTRY_TRACING_SAMPLE_RATE || 0.1,

    environment: env.APP_ENV,
    release: env.APP_VERSION,
    ignoreErrors: [
      'Object Not Found Matching Id',
      'TypeError: Load failed',
      "null is not an object (evaluating 'Object.prototype.hasOwnProperty.call(o,\"telephone\")')"
    ],
    ignoreUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Skip external scripts errors
      /acsbapp\.com/,
      /hscollectedforms\.net/,
      /hubspot\.com/,
      /hubapi\.com/,
      /posthog\.com/,
      /hs-scripts\.com/,
    ]
  })
}

const render = () => {
  loadableReady(() => {
    const state = window.__APP_STATE__ || {}

    const client = createClient({ url: state.env.API_URL })
    client.hydrate(state.api)

    const loc = window.location
    const url = `${loc.origin}${loc.pathname === '/' ? '' : loc.pathname}`

    const appContext = state.app || {}

    const Root = (
      <Sentry.ErrorBoundary fallback={ErrorPage}>
        <CookieProvider value={cookies}>
          <ApiProvider client={client}>
            <HelmetProvider>
              <NotificationProvider>
                <BrowserRouter>
                  <PostHogProvider client={posthog}>
                    <AppContext.Provider value={appContext}>
                      <App url={url} />
                    </AppContext.Provider>
                  </PostHogProvider>
                </BrowserRouter>
              </NotificationProvider>
            </HelmetProvider>
          </ApiProvider>
        </CookieProvider>
      </Sentry.ErrorBoundary>
    )

    hydrateRoot(document.getElementById('root'), Root )
  })
}

const polyfills = []

if (!window.fetch || (!!window.MSInputMethodContext && !!document.documentMode)) {
  polyfills.push(import('react-app-polyfill/ie11'))
}

if (polyfills.length) {
  Promise.all(polyfills).then(render)
} else {
  render()
}

if (module.hot) {
  module.hot.accept()
}
