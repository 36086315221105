import React from 'react'
import mic from 'assets/svg/mic.svg'
import LazyImage from 'app/theme/LazyImage'

export default function BrandPromotions(props) {
  return (
    <a
      href="/experiential-marketing-agency/"
      className={[
        'bg-red text-white text-center p-5',
        props.className
      ]}
    >
      <h2 className="text-white font-platform text-5xl mb-5" style={{ lineHeight: '.85' }}>
        Brand Promotions
      </h2>
      <div>
        <LazyImage
          src={mic}
          alt="Brand Promotions"
          className="m-auto"
          style={{
            maxWidth: 160
          }}
        />
      </div>
      <p className="font-light leading-tight text-xl mt-5">
        Custom Food Truck Advertising Takeovers
      </p>
    </a>
  )
}
