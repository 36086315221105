import loadable from '@loadable/component'

export default [
  {
    path: '/',
    component: loadable(() => import('./pages/HomePage')),
  },
  {
    path: '/review/',
    component: loadable(() => import('./pages/ReviewPage')),
  },
  {
    path: '/events/:eventId',
    component: loadable(() => import('./events/EventPage')),
  },
  {
    path: '/events/:placeSlug/:eventId',
    component: loadable(() => import('./events/EventPage')),
  },
  {
    path: '/landmarks/:placeSlug/:landmarkSlug',
    component: loadable(() => import('./landmarks/LandmarkPage')),
  },
  {
    path: '/food-trucks/',
    component: loadable(() => import('./pages/FoodTrucksPage')),
  },
  {
    path: '/food-trucks/search/:term/:page(\\d+)?',
    component: loadable(() => import('./search/SearchPage')),
  },
  {
    path: '/food-trucks/:regionCode(\\w{2})/:citySlug/search/:searchTerm([a-z-]+)?/:page(\\d+)?',
    exact: false,
    component: loadable(() => import('./FoodTrucksRouter')),
  },
  {
    path: '/food-trucks/:regionCode(\\w{2})/:citySlug/:tagSlug([a-z-]+)?/:page(\\d+)?',
    exact: false,
    component: loadable(() => import('./FoodTrucksRouter')),
  },
  {
    path: '/food-trucks/:placeSlug/search/:searchTerm/:page(\\d+)?',
    component: loadable(() => import('./FoodTrucksRouter')),
  },
  {
    path: '/food-trucks/:placeSlug/:tagSlug([a-z-]+)?/:page(\\d+)?',
    component: loadable(() => import('./FoodTrucksRouter')),
  },
  {
    path: '/catering/:citySlug/:tagSlug/',
    component: loadable(() => import('./catering/CityTagPage')),
  },
  {
    path: '/experiential-marketing-agency/',
    component: loadable(() => import('./agency/AgencyPage')),
  },
  {
    path: '/experiential-marketing-agency/food-truck-promotions/',
    component: loadable(() => import('./agency/FoodTruckPromotionsPage')),
  },
  {
    path: '/experiential-marketing-agency/branded-food-trucks/',
    component: loadable(() => import('./agency/BrandedFoodTrucksPage')),
  },
  {
    path: '/experiential-marketing-agency/mobile-marketing-tours/',
    component: loadable(() => import('./agency/MobileMarketingToursPage')),
  },
  {
    path: '/experiential-marketing-agency/:slug/',
    component: loadable(() => import('./agency/CaseStudyPage')),
  },
  {
    path: '/business-lunch-catering/',
    component: loadable(() => import('./occasions/BusinessLunchCateringPage')),
  },
  {
    path: '/food-truck-catering/campus-catering/',
    component: loadable(() => import('./occasions/CampusCateringPage')),
  },
  {
    path: '/food-truck-catering/corporate-catering/',
    component: loadable(() => import('./occasions/CorporateCateringPage')),
  },
  {
    path: '/corporate-catering/los-angeles-catering/',
    component: loadable(() => import('./occasions/LosAngelesCorporateCateringPage')),
  },
  {
    path: '/food-truck-catering/food-truck-wedding/',
    component: loadable(() => import('./occasions/FoodTruckWeddingPage')),
  },
  {
    path: '/food-truck-catering/large-event-food-concessions/',
    component: loadable(() => import('./occasions/LargeEventConcessionsPage')),
  },
  {
    path: '/food-truck-catering/film-production-catering/',
    component: loadable(() => import('./occasions/FilmProductionCateringPage')),
  },
  {
    path: '/food-truck-catering/local-community-concessions/',
    component: loadable(() => import('./occasions/LocalCommunityConcessionsPage')),
  },
  {
    path: '/food-truck-catering/food-truck-community-builder-program/',
    component: loadable(() => import('./occasions/CommunityBuilderProgramPage')),
  },
  {
    path: '/food-truck-catering/party-catering/',
    component: loadable(() => import('./occasions/PartyCateringPage')),
  },
  {
    path: '/food-truck-catering/food-trucks-for-bars-breweries-wineries/',
    component: loadable(() => import('./occasions/BarBreweryWineryPage')),
  },
  {
    path: '/food-truck-catering/conference-and-event-catering/',
    component: loadable(() => import('./occasions/ConferenceCateringPage')),
  },
  {
    path: '/food-truck-catering/office-lunch-catering-program/',
    component: loadable(() => import('./occasions/OfficeLunchCateringPage')),
  },
  {
    path: '/food-truck-catering/university-food-service/',
    component: loadable(() => import('./occasions/UniversityCateringPage')),
  },
  {
    path: '/food-truck-catering/holiday-party-catering/',
    component: loadable(() => import('./occasions/HolidayPartyCateringPage')),
  },
  {
    path: '/food-truck-catering/affordable-catering/',
    component: loadable(() => import('./pages/AffordableCateringPage')),
  },
  {
    path: '/food-truck-catering/business-lunch-food-court/',
    component: loadable(() => import('./occasions/BusinessLunchFoodCourtPage')),
  },
  {
    path: '/food-truck-catering/film-production-catering-nyc/',
    component: loadable(() => import('./occasions/FilmProductionNYCPage')),
  },
  {
    path: '/food-truck-catering/happy/',
    component: loadable(() => import('./occasions/ReferralProgramPage')),
  },
  {
    path: '/food-truck-catering/placemaking-retail-properties/',
    component: loadable(() => import('./occasions/RetailPropertyCateringPage')),
  },
  {
    path: '/food-truck-catering/jacksonville-food-truck-catering/',
    component: loadable(() => import('./occasions/JacksonvilleCateringPage')),
  },
  {
    path: '/food-truck-catering/employee-appreciation-catering/',
    component: loadable(() => import('./occasions/EmployeeAppreciationCateringPage')),
  },
  {
    path: '/food-truck-catering/esports/',
    component: loadable(() => import('./occasions/EsportsCateringPage')),
  },
  {
    path: '/food-truck-catering/lease-a-food-truck/',
    component: loadable(() => import('./pages/LeaseTruckProgramPage')),
  },
  {
    path: '/food-truck-catering/hungryfordemocracy-1/',
    component: loadable(() => import('./pages/HungryForDemocracyPage')),
  },
  {
    path: '/food-truck-catering/film-catering-los-angeles/',
    component: loadable(() => import('./occasions/FilmProductionLAPage')),
  },
  {
    path: '/food-truck-catering/guide-to-booking-a-food-truck-wedding/',
    component: loadable(() => import('./pages/WeddingGuidePage')),
  },
  {
    path: '/food-truck-catering/foodservice-providers/',
    component: loadable(() => import('./pages/FoodServiceProviderPage')),
  },
  {
    path: '/food-truck-catering/wedding-frequently-asked-questions/',
    component: loadable(() => import('./pages/WeddingFAQPage')),
  },
  {
    path: '/food-truck-catering/roaming-hunger-guarantee/',
    component: loadable(() => import('./pages/GuaranteePage')),
  },
  {
    path: '/food-truck-catering/guarantee-faq/',
    component: loadable(() => import('./pages/GuaranteeFAQ')),
  },
  {
    path: '/food-truck-catering/community-concessions-event-guarantee/',
    component: loadable(() => import('./pages/EventGuarantee')),
  },
  {
    path: '/food-truck-catering/catering-payment-protection/',
    component: loadable(() => import('./pages/PaymentProtectionPage')),
  },
  {
    path: '/food-truck-catering/covid-safety/',
    component: loadable(() => import('./pages/CovidSafetyPage')),
  },
  {
    path: '/food-truck-catering/tesla-case-study/',
    component: loadable(() => import('./pages/TeslaCaseStudyPage')),
  },
  {
    path: '/food-truck-catering/cal-medical-association/',
    component: loadable(() => import('./pages/CalMedicalAssociationPage')),
  },
  {
    path: '/food-truck-catering/hospital-week/',
    component: loadable(() => import('./pages/HospitalWeekPage')),
  },
  {
    path: '/food-truck-catering/catering-terms/',
    component: loadable(() => import('./pages/CateringTermsConditionsPage')),
  },
  {
    path: '/food-truck-catering/terms/',
    component: loadable(() => import('./pages/CateringTermsPage')),
  },
  {
    path: '/food-truck-catering/:cuisine',
    component: loadable(() => import('./pages/CuisinePage')),
  },
  {
    path: '/food-truck-catering/',
    component: loadable(() => import('./pages/FoodTruckCateringPage')),
  },
  // {
  //   path: '/food-truck-catering/terms/b14/',
  //   component: loadable(() => import('./pages/CustomerCateringAgreement')),
  // },
  {
    path: '/:citySlug/food-truck-catering/:catererSlug?',
    component: loadable(() => import('./pages/CityCateringPageV2')),
  },
  {
    path: '/birthday-party-catering/',
    component: loadable(() => import('./occasions/BirthdayCateringPage')),
  },
  {
    path: '/pages/privacy/policy/',
    component: loadable(() => import('./pages/PrivacyPolicyPage')),
  },
  {
    path: '/pages/privacy/7_20_2020/',
    component: loadable(() => import('./pages/privacy/Policy_7_20_2020')),
  },
  {
    path: '/pages/privacy/privacy-request/',
    component: loadable(() => import('./pages/PrivacyRequestPage')),
  },
  {
    path: '/pages/privacy/opt-out/',
    component: loadable(() => import('./pages/OptOutPage')),
  },
  {
    path: '/pages/terms_and_conditions/',
    component: loadable(() => import('./pages/TermsConditionsPage')),
  },
  {
    path: '/pages/occasion/',
    component: loadable(() => import('./occasions/OccasionPage')),
  },
  {
    path: '/pages/about/',
    component: loadable(() => import('./pages/AboutPage')),
  },
  {
    path: '/company/careers/',
    component: loadable(() => import('./pages/CareersPage')),
  },
  {
    path: '/catering/questions-and-answers/',
    component: loadable(() => import('./pages/CateringFAQPage')),
  },
  {
    path: '/press/',
    component: loadable(() => import('./pages/PressPage')),
  },

  // {
  //   path: '/styleguide/',
  //   exact: false,
  //   component: loadable(() => import('./styleguide/StyleguidePage')),
  // },
  {
    path: '/:abbrev(\\w{2})/',
    component: loadable(() => import('./cities/CityAbbrev')),
  },
  {
    path: '/page-preview/',
    component: loadable(() => import('./pages/CustomPagePreview')),
  },
  {
    path: '/:catererSlug/',
    component: loadable(() => import('./caterers/ProfilePage')),
  },
  {
    path: '/event/:leadToken',
    component: loadable(() => import('./pages/lead-dashboard/LeadDashboardPage'), { ssr: false })
  },
  {
    path: '/book-now/:leadToken',
    component: loadable(() => import('./pages/book-now/BookNowPage'), { ssr: false })
  },
  {
    path: "/food-truck-catering/:citySlug/occasion/:occasionSlug/",
    component: loadable(() => import("./occasions/CityOccasions/CityOccasionPage")),
  },
  {
    path: '/*',
    exact: false,
    component: loadable(() => import('./pages/CustomPage')),
  },
]
