import React, { useState } from 'react'
import styles from './Sidebar.module.css'
import BlogLinks from 'app/theme/BlogLinks'
import BrandPromotions from 'app/theme/banners/BrandPromotions'
import { useParams } from 'react-router-dom'
import { useSearchParams } from 'app/theme/useSearchParams'
import { useLocation } from 'react-router-dom'
import LeadModal from 'app/leads/LeadModal'
import { useModalState } from 'lib/modal'

export default function Sidebar(props) {
  const { city, region, tag, className } = props

  return (
    <div className={['grid gap-10', className]}>
      <Intro city={city} tag={tag} region={region} />
      <SearchBox city={city} tag={tag} region={region}/>
      <div className="grid gap-5">
        <BrandPromotions />
      </div>
      <BlogLinks />
    </div>
  )
}

function Intro({ city, region, tag }) {
  let url
  if (city) {
    url = `/${city.url_slug}/food-truck-catering/`
  } else if (region) {
    url = `/${region.url_slug}/food-truck-catering/`
  } else {
    url = '/food-truck-catering/'
  }

  const { openModal, ...modalState } = useModalState()

  return (
    <>
      <LeadModal
        {...modalState}
      />
      <div>
        <h2 className={styles.heading}>
          <a href="/food-truck-catering/">Need {tag?.primary ? tag.name : "Food" } Truck Catering?</a>
        </h2>
        <p className="font-light mb-4">
          Food truck catering for your next event. Protected by <span />
          <a
            href="/food-truck-catering/roaming-hunger-guarantee/"
            className="text-blue"
            children="The 100% Roaming Hunger Guarantee"
            target="_blank"
          />
          . Fast, easy and delicious. Book it here.
        </p>
        {city ? (
          <>
            <button
              onClick={openModal}
              className="btn btn-blue px-4 py-2 text-xl mr-1"
            >
              Get Started
            </button>

            <a 
              href={url}
              className="block mt-5 font-light text-blue underline"
            >
              Learn more about how to hire a food truck in {city?.name}.
            </a>
          </>
        ) : (
          <a
            href={url}
            className="btn btn-blue px-4 py-2 text-xl mr-1"
          >
            Get Started
          </a>
        )}      
      </div>
    </>
  )
}

function SearchBox(props) {
  const { city, region, tag } = props
  const location = useLocation()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [term, setTerm] = useState(searchParams.search || "")

  return (
    <form
      className=""
      onSubmit={event => {
        event.preventDefault()
        const search = window.encodeURIComponent(term)

        if (city || region) {
          window.location.href = `${location.pathname.replace(`/${params.page}/`, "/1/")}${term ? `?search=${search}` : ""}`
        } else {
          window.location.href = `/food-trucks/search/${search}`
        }
      }}
    >
      <h2 className={styles.heading}>
        Search {tag?.primary ? tag.name : "Food" } Trucks
      </h2>
      <div className="flex">
        <input
          type="text"
          value={term}
          className="border border-r-0 border-gray-400 flex-1 px-3 py-2 focus:outline-none focus:border-blue"
          onChange={event => {
            setTerm(event.target.value)
          }}
        />
        <button
          type="submit"
          className="btn btn-blue px-4 py-2 text-xl"
          children="Search"
        />
      </div>
    </form>
  )
}
