import React, { useState, useEffect } from 'react'
import { AlertCircle } from 'react-feather'
import posthog from 'posthog-js'
import { useCookie } from 'lib/cookies'

export default function CookieConsent(props) {
  const [show, setShow] = useState(false)
  const [cookie, setCookie, removeCookie] = useCookie('cookie_consent', { path: '/'})

  useEffect(() => {
    if (!cookie) {
      return setShow(true)
    }
    const parsed_cookie = typeof cookie !== 'object' ? JSON.parse(cookie) : cookie

    if (!parsed_cookie['sessionReplayStatus']) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [cookie])

  const dismiss = () => {
    setShow(false)
  }

  const accept = () => {
    setCookie({sessionReplayStatus: 'accepted'})
    posthog.opt_in_capturing()
    dismiss()
  }

  return show && (
    <div
      className="fixed bottom-0 left-0 right-0 w-full z-20"
    >
      <div className="max-w-6xl mx-auto p-2 shadow-lg">
        <div className="flex rounded bg-navy shadow-lg">
          <div className="flex-1 p-4 text-white">
            <p className="text-xl leading-none mb-1">Cookie Consent</p>
            <p className="leading-tight">
              This site uses cookies and other technologies employed by
              Roaming Hunger and the companies we work with to enable and
              improve functionality, analyze site use, generate user and
              site analytics, and facilitate advertising. See our
              {/* Deep link to section in the Privacy Policy page discussing session recordings */}
              <a
                className="underline text-blue-300 mx-1"
                href="/pages/privacy/policy#session-replay-technologies"
                target="_blank"
              >
                Privacy Policy
              </a>
              for details or to opt out. By continuing to browse this site, you agree to
              our
              {/* Link to general Terms of Use */}
              <a
                className="underline text-blue-300 mx-1"
                href="/food-truck-catering/terms/"
                target="_blank"
              >
                Terms of Use
              </a>
              and
              {/* Link to general Privacy Policy page */}
              <a
                className="underline text-blue-300 mx-1"
                href="/pages/privacy/policy/"
                target="_blank"
              >
                Privacy Policy
              </a>
              .
            </p>
            <div className="text-right pt-4">
              <div 
                onClick={accept}
                className="btn cursor-pointer btn-white text-navy rounded border px-4 py-1 ml-4"
              >
                Accept
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
