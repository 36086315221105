import React from 'react';
import { Layout } from 'app/theme/layout'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

export default function ErrorPage({ message }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = 500
        }
        return (
          <Layout>
            <Helmet>
              <title>500 - Roaming Hunger</title>
            </Helmet>
            <div className="container py-10">
            <h1 className="font-bold font-platform text-blue leading-tight" style={{ 'fontSize': 90}}>Oh No!</h1>
            <p className="text-xl mb-5">Sorry this page isn't working currently. You can try some of these links instead:</p>
            <ul className="text-blue">
              <li><a className="hover:underline" href="/">Home</a></li>
              <li><a className="hover:underline" href="/food-truck-catering/">Catering</a></li>
              <li><a className="hover:underline" href="/food-trucks/">Find Trucks</a></li>
              <li><a className="hover:underline" href="/experiential-marketing-agency/">Brand Activations</a></li>
              <li><a className="hover:underline" href="/contact/">Contact Us</a></li>
            </ul>
            </div>
          </Layout>
        )
      }}
    />
  )
}
