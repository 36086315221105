import React from 'react'
import { useAppContext } from 'app/AppContext'

export default function StaticContent({ children, ...rest }) {
  const context = useAppContext()

  return (typeof window === 'undefined' || !context.ssr) ? (
    <div {...rest}>
      {children}
    </div>
  ) : (
    <div
      {...rest}
      dangerouslySetInnerHTML={{ __html: '' }}
      suppressHydrationWarning
    />
  )
}
