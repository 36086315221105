import { useEffect, useMemo } from 'react'
import { usePostHog } from './usePostHog'

const AUTO_START_DEFAULT = process.env.NODE_ENV === 'production'

export function useSessionRecording({ auto = AUTO_START_DEFAULT } = {}) {
  const { posthog } = usePostHog()

  const controls = useMemo(() => ({
    start:() => posthog.startSessionRecording(),
    stop: () => posthog.stopSessionRecording(),
  }), [posthog, auto])

  useEffect(() => {
    if (auto) {
      controls.start()
      return () => controls.stop()
    }
  }, [controls])

  return controls
}

