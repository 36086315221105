import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  isValidElement,
} from 'react'

import { createPortal } from 'react-dom'

const ESC_KEY = 27

export function useModalState(openInitally = false) {
  const [isOpen, setOpen] = useState(openInitally)

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  const toggleModal = useCallback(() => {
    setOpen(open => !open)
  }, [])

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
  }
}

export function useModal(Component, defaultProps) {
  const state = useModalState()

  const Modal = useCallback(
    props => (
      <Component
        isOpen={state.isOpen}
        closeModal={state.closeModal}
        {...defaultProps}
        {...props}
      />
    ),
    [state.isOpen]
  )

  return {
    Modal,
    ...state,
  }
}

export function Modal(props) {
  let {
    isOpen = false,
    closeModal = () => {},
    overlay = true,
    closeOnOutsideClick = true,
    closeOnEsc = true,
    container: Container = ModalContainer,
    rootSelector,
    ...rest
  } = props

  const [root, setRoot] = useState()
  const innerRef = useRef()

  const onOutsideClick = event => {
    event.stopPropagation()

    const target = event.target

    if (
      closeOnOutsideClick &&
      innerRef.current &&
      (innerRef.current === target || !innerRef.current.contains(target))
    ) {
      closeModal()
    }
  }

  const onKeyDown = ({ keyCode }) => {
    if (closeOnEsc && keyCode === ESC_KEY) {
      closeModal()
    }
  }

  useEffect(() => {
    setRoot(rootSelector ? document.querySelector(rootSelector) : document.body)
  }, [])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    document.addEventListener('keydown', onKeyDown)
    document.body.style.overflow = 'hidden'

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.body.style.overflow = null
    }
  }, [isOpen])

  if (!root || !isOpen) {
    return null
  }

  if (typeof overlay === 'function') {
    overlay = React.createElement(overlay)
  }

  const element = (
    <Container
      isOpen={isOpen}
      onClick={onOutsideClick}
      innerRef={innerRef}
      overlay={overlay}
      children={<div {...rest} />}
    />
  )

  return createPortal(element, root)
}

export function ModalContainer(props) {
  const { onClick, innerRef, overlay, children } = props

  return (
    <div
      onClick={onClick}
      style={{
        position: 'fixed',
        zIndex: 50,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      {overlay ? (
        isValidElement(overlay) ? (
          overlay
        ) : (
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: '#000',
              opacity: 0.5,
            }}
          />
        )
      ) : null}

      <div
        style={{
          position: 'relative',
          overflow: 'auto',
          minHeight: 0,
          flexGrow: 1,
        }}
      >
        <div
          ref={innerRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            minWidth: '100vw'
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
