import React from 'react'
import logo from 'assets/svg/logo-bg.svg'
import appleIcon from 'assets/img/apple-store.png'
import googleIcon from 'assets/img/google-play.png'
import { Twitter, Instagram, Facebook } from 'react-feather'
import { format } from 'date-fns'
import styles from './Footer.module.css'
import env from 'env'
import LazyImage from 'app/theme/LazyImage'

export default function Footer(props) {
  return (
    <footer className={[styles.Footer, 'font-light']}>
      <div className="container pt-10 pb-3">

        <div className="flex flex-wrap -mx-4">
          <ul className="w-1/2 md:w-1/4 px-4">
            <li><h4>Food Trucks</h4></li>
            <li><a href="/food-trucks/">Find Trucks</a></li>
            <li><a href="/food-truck-catering/">Catering</a></li>
            <li><a href="//vendor.roaminghunger.com/">Vendors</a></li>
            <li><a href="//mobilefoodalliance.com/">Buy/Lease a Truck</a></li>
            <li><a href="/experiential-marketing-agency/">Brand Activations</a></li>
          </ul>
          <ul className="w-1/2 md:w-1/4 px-4">
            <li><h4>Company</h4></li>
            <li><a href="/pages/about/">About</a></li>
            <li><a href="/blog/">Blog</a></li>
            <li><a href="/press/">Press</a></li>
          </ul>
          <div className="flex flex-wrap w-full md:w-1/4">
            <ul className="w-1/2 md:w-full px-4">
              <li><h4>Vendors</h4></li>
              <li><a href="//vendor.roaminghunger.com/">Why Work With Us?</a></li>
              <li><a href="//vendor.roaminghunger.com/login/">Vendor Login</a></li>
              <li><a href="//vendor.roaminghunger.com/get-started/">Add My Business</a></li>
            </ul>
            <ul className="w-1/2 md:w-full px-4">
              <li><h4>Questions?</h4></li>
              <li>
                <p>Call Us Monday - Friday</p>
                <p>9-5pm PST 1-800-568-9370</p>
              </li>
              <li><a href="/catering/questions-and-answers/">Catering Frequently Asked Questions</a></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 text-center md:text-left">

            <div className={[styles.icons, 'flex items-center justify-center md:justify-start mb-3']}>
              <LazyImage src={logo} height="60" width="60" alt="Roaming Hunger" />
              <a href="https://twitter.com/roaminghunger" target="_blank" rel="noreferrer">
                <Twitter />
              </a>
              <a href="https://www.instagram.com/roaminghunger/" target="_blank" rel="noreferrer">
                <Instagram />
              </a>
              <a href="https://www.facebook.com/roaminghunger" target="_blank" rel="noreferrer">
                <Facebook />
              </a>
            </div>
            <div className="text-xs">
              <a href="/sitemap.xml">Sitemap</a>
              <span className="px-1">|</span>
              <a href="/pages/privacy/policy/">Privacy Policy</a>
              <span className="px-1">|</span>
              <a href="/pages/terms_and_conditions/">Terms &amp; Conditions</a>
              <div>
                <a href="/pages/privacy/opt-out/">Do Not Sell or Share My Personal Information</a>
              </div>
              <div>&copy; {format(new Date(), 'yyyy')} Roaming Hunger</div>
              <div>
                Version: {env.APP_VERSION || 'n/a'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
