import React from 'react'
import { useInView } from 'react-intersection-observer'

export default function LazyImage(props) {
  const {
    src,
    placeholder,
    rootMargin = '25px',
    threshold = 0,
    triggerOnce = true,
    ...rest
  } = props

  const [ref, inView] = useInView({
    rootMargin: rootMargin,
    threshold: threshold,
    triggerOnce: triggerOnce
  })

  return (
    <img {...rest} ref={ref} src={inView ? src : placeholder} />
  )
}
