import React, { useRef, useMemo } from 'react'
import loadable from '@loadable/component'
import { useLocation } from 'react-router-dom'
import { useFeatureFlagVariantKey } from 'lib/posthog'

const EXPERIMENTS = {
  B2B_SKIPS_LINE: 'b2b_skips_line'
}

const EXPERIMENT_VARIANTS = {
  TEST: 'test'
}

const LeadFormModal = loadable(() => import('./LeadFormModal'), {
  ssr: false
})

export default function LeadModal(props) {
  const ref = useRef()
  const location = useLocation()

  const b2bSkipsLineVariant = useFeatureFlagVariantKey(EXPERIMENTS.B2B_SKIPS_LINE)

  const experiments = useMemo(() => ({
    [EXPERIMENTS.B2B_SKIPS_LINE]: b2bSkipsLineVariant
  }), [b2bSkipsLineVariant])

  const isExperimentEnabled = (experimentKey, expectedVariant) => {
    const variant = experiments[experimentKey]
    return variant === expectedVariant
  }

  // Allow the user to manually turn on the experiment using a query parameter
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return {
      lead_corporate_experiment: params.get('lead_corporate_experiment'),
    }
  }, [location])

  if (!ref.current && props.isOpen) {
    ref.current = true
  }

  if (ref.current === undefined) {
    return null
  }

  return <LeadFormModal {...props} experiments={experiments} />
}
