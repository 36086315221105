import React from 'react'
import Header from './Header'
import Footer from './Footer'
import LeadModal from 'app/leads/LeadModal'
import { useModalState } from 'lib/modal'

export default function Layout(props) {
const {openModal, ...modalState} = useModalState()
  const {
    className,
    containerClassName,
    header,
    footer,
    children,
  } = props

  const headerWithModal = addModalToHeader(header, openModal)

  return (
    <div
      className={[
        containerClassName,
        'min-h-screen flex flex-col',
      ]}
    >
      {headerWithModal ? headerWithModal : <Header openModal={openModal} />}
      <LeadModal
        {...modalState}
      />
      <main
        className={[
          className,
          'flex-1'
        ]}
      >
        {children}
      </main>
      {footer ? footer : <Footer />}
    </div>
  )
}

const addModalToHeader = (headerFromProps, openModal) => {
  if (!headerFromProps) return

  let header = {
    ...headerFromProps,
    props: {
      ...headerFromProps.props,
      openModal: openModal
    }
  }

  return header
}